import React, { useState } from "react"
import styled from "styled-components"
import { Form, Toast } from "react-bootstrap"
import ReCAPTCHA from "react-google-recaptcha"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Heading24, Paragraph16 } from "../../styles/PageStyles"

export const FormNotification = ({ show, setShow }) => {
  return (
    <StyledToast show={show}>
      <Toast.Header closeButton={false}>
        <Paragraph16 className="me-auto">Form Notification</Paragraph16>
        <button
          type="button"
          class="btn-close btn-close-white"
          onClick={() => setShow(false)}
          aria-label="Close"
          data-dismiss="toast"
        ></button>
      </Toast.Header>
      <Toast.Body>Thank you! Your inquries has been sent.</Toast.Body>
    </StyledToast>
  )
}

const InquiriesFormId = ({ name, image }) => {
  const [validated, setValidated] = useState(false)
  const [recaptcha, setRecaptcha] = useState(false)
  const [recaptchaStyle, setRecaptchaStyle] = useState({
    border: "none",
  })

  const [formData, setFormData] = useState({
    subject: `[${name}] You have new form submission`,
    name: "",
    email: "",
    inquirySubject: "",
    message: "",
  })

  const [formKey, setFormKey] = useState(0)
  const [showNotification, setShowNotification] = useState(false)

  function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }
    return formData
  }

  const formPost = () => {
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": name,
        ...formData,
      }),
    }).catch((error) => alert(error))

    setShowNotification(true)
    if (!showNotification) {
      setTimeout(() => {
        setShowNotification(false)
      }, 5000)
    }
  }

  const handleChange = (event) => {
    const newFormData = { ...formData }
    newFormData[event.target.id] = event.target.value
    setFormData(newFormData)
  }

  const handleNameChange = (event) => {
    const re = /^[a-zA-Z\s]*$/
    if (event.target.value === "" || re.test(event.target.value)) {
      const newFormData = { ...formData }
      newFormData[event.target.id] = event.target.value
      setFormData(newFormData)
    }
  }

  const handleSubjectChange = (event) => {
    const re = /^[a-zA-Z0-9\s]*$/
    if (event.target.value === "" || re.test(event.target.value)) {
      const newFormData = { ...formData }
      newFormData[event.target.id] = event.target.value
      setFormData(newFormData)
    }
  }

  const reCaptchaStyle = {
    border: recaptchaStyle.border,
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
      setValidated(true)
    } else if (recaptcha === false) {
      setRecaptchaStyle({ border: "1px solid red" })
      setTimeout(() => setRecaptchaStyle({ border: "none" }), 1000)
      event.stopPropagation()
    } else {
      formPost()
      setFormKey(formKey + 1)
      setRecaptcha(false)
      setFormData({
        subject: `[${name}] You have new form submission`,
        name: null,
        email: null,
        inquirySubject: null,
        message: null,
      })
      setValidated(false)
    }
  }

  const enableSubmit = () => {
    setRecaptcha(true)
  }

  const disableSubmit = () => {
    setRecaptcha(false)
  }
  return (
    <StyledForm
      name={name}
      data-netlify="true"
      method="POST"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
      data-netlify-honeypot="bot-field"
      noValidate
      validated={validated}
      key={formKey}
    >
      <input type="hidden" name="subject" value={formData.subject} />
      <FormImg>
        <GatsbyImage image={getImage(image)} alt={name} />
      </FormImg>
      <Form.Group className="form-wrapper">
        <Heading24>{name}</Heading24>
        <Form.Group className="group">
          <Form.Label className="text-label">Nama lengkap</Form.Label>
          <Form.Control
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleNameChange}
            required
          />
        </Form.Group>
        <Form.Group className="group">
          <Form.Label className="text-label">Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="group">
          <Form.Label className="text-label">Perihal</Form.Label>
          <Form.Control
            type="text"
            name="inquirySubject"
            id="inquirySubject"
            value={formData.inquirySubject}
            onChange={handleSubjectChange}
            required
          />
        </Form.Group>
        <Form.Group className="group">
          <Form.Label className="text-label">Pesan</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="message"
            id="message"
            placeholder="Ketik pesan Anda di sini."
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="group">
          <Form.Label className="text-label"></Form.Label>
          <div className="btn-group">
            <ReCAPTCHA
              className="recaptcha recaptcha-normal"
              sitekey="6LdXYIceAAAAADxQyNJc58Y05vb38musHuFi70h9"
              onExpired={disableSubmit}
              onChange={enableSubmit}
              size="normal"
              style={reCaptchaStyle}
            />
            <ReCAPTCHA
              className="recaptcha recaptcha-compact"
              sitekey="6LdXYIceAAAAADxQyNJc58Y05vb38musHuFi70h9"
              onExpired={disableSubmit}
              onChange={enableSubmit}
              size="compact"
              style={reCaptchaStyle}
            />
            <button className="btn" type="submit">
              Submit
            </button>
          </div>
        </Form.Group>
      </Form.Group>
      <FormNotification show={showNotification} setShow={setShowNotification} />
    </StyledForm>
  )
}

export default InquiriesFormId

const StyledToast = styled(Toast)`
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 999;
  background: #fff;
  border: 1px solid #ffffff;

  .toast-header {
    background: #149be1;

    p {
      color: #ffffff;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 500px) {
    right: 50%;
    margin-right: -150px;
    width: 300px !important;
  }
`

const StyledForm = styled(Form)`
  display: flex;
  background: #ffffff;
  box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-bottom: 50px;

  .form-wrapper {
    padding: 50px;
    width: 100%;
  }

  h1 {
    font-weight: bold;
    color: #0073af;
    margin-bottom: 50px;
  }

  .group {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 15px;
  }

  .text-label {
    margin: 0;
    padding-top: 9px;
    width: 200px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0.08em;
    color: #5b5d5e;
  }

  input,
  textarea {
    background: #f1f3f4;
    border-radius: 5px;
    border: none;
  }

  textarea {
    height: 110px;
  }

  .recaptcha {
    float: left;
  }

  .recaptcha-normal {
    @media only screen and (max-width: 500px) {
      display: none !important;
    }
  }

  .recaptcha-compact {
    @media only screen and (min-width: 500px) {
      display: none !important;
    }
  }

  .btn-group {
    display: block;
    width: 100%;
  }

  .btn {
    width: max-content;
    height: 42px;
    background: #0073af;
    border-radius: 100px !important;
    border: none;
    padding: 0 30px;
    color: #fff;
    cursor: pointer;
    float: right;

    &:focus,
    &:active:hover {
      outline: none;
      box-shadow: none;
    }

    font-family: Montserrat;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.06em;
    color: #ffffff;
    white-space: nowrap;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .form-wrapper {
      padding: 25px 50px;
    }

    .text-label,
    textarea {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1365px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 769px) {
    .btn {
      margin-top: 25px;
      font-size: 16px;
      line-height: 20px;
    }

    .text-label {
      font-size: 16px;
      line-height: 20px;
      font-style: normal;
      letter-spacing: 0.06em;
    }
  }

  @media only screen and (max-width: 500px) {
    .group {
      flex-wrap: wrap;
      justify-content: left;
    }

    .btn-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      button {
        flex: 0 0 50%;
      }
    }
  }
`

const FormImg = styled(Form.Group)`
  display: block;
  width: 800px;
  border-radius: 20px 0px 0px 20px;
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1440px) {
    width: 600px;
  }

  @media only screen and (max-width: 1365px) {
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    height: 430px;
  }
`
