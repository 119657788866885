import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import InquiriesForm from "../InquiriesFormId"
import BgContactUs from "../../../multimedia/building-contact-us.png"
import LinkedIn from "../../../../static/svg/linked-in-icon.svg"
import { graphql, useStaticQuery } from "gatsby"
import { GradientFooterBg } from "../../Footer"
import { Heading32, Paragraph18 } from "../../../styles/PageStyles"

const ContactUsContentId = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "contacts-id" } }) {
        frontmatter {
          businessInqBg {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          generalInqBg {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          connect {
            title
            address {
              line
            }
            linkedIn {
              link
            }
            tel
            fax
            map {
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              link
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const { connect } = frontmatter

  return (
    <ContactUsContainer fluid>
      <Inquiries>
        <div id="business-inquiries">
          <InquiriesForm
            name="Permintaan Bisnis"
            image={getImage(frontmatter.businessInqBg)}
          />
        </div>
        <div id="general-inquiries">
          <InquiriesForm
            name="Permintaan Umum"
            image={getImage(frontmatter.generalInqBg)}
          />
        </div>
      </Inquiries>
      <ContactUsBg id="connect" bg={BgContactUs} />
      <ContactUsRow>
        <Col className="office-address" lg={6}>
          <Heading32>{connect.title}</Heading32>
          {connect.address.map((item, index) => (
            <Paragraph18 key={index}>{item.line}</Paragraph18>
          ))}
        </Col>
        <Col className="contact-info" lg={3}>
          <a href={connect.linkedIn.link} target="_blank" rel="noreferrer">
            <LinkedIn />
          </a>
          <Paragraph18>{`Tel.: ${connect.tel}`}</Paragraph18>
          <Paragraph18>{`Fax.: ${connect.fax}`}</Paragraph18>
        </Col>
        <Col className="office-map" lg={3}>
          <a href={connect.map.link} target="_blank" rel="noreferrer">
            <GatsbyImage
              image={getImage(connect.map.image)}
              alt="JFEEI Office Map"
            />
          </a>
        </Col>
      </ContactUsRow>
      <GradientFooterBg />
    </ContactUsContainer>
  )
}

export default ContactUsContentId

const ContactUsContainer = styled(Container)`
  padding: 0 0 100px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`

const Inquiries = styled.div`
  padding: 0 10%;
  position: relative;
  width: 100%;

  @media only screen and (max-width: 426px) {
    padding: 0 5%;
  }
`

const ContactUsBg = styled.div`
  position: relative;
  width: 100%;
  height: 575px;
  margin: 50px 0;
  background: url(${(props) => props.bg || null}) no-repeat;
  background-position: 15% center;
  background-size: cover;

  @media only screen and (max-width: 769px) {
    height: 470px;
  }

  @media only screen and (max-width: 426px) {
    height: 275px;
  }
`

const ContactUsRow = styled(Row)`
  position: relative;
  z-index: 2;
  margin-top: -225px;
  width: 80%;
  background: #fff;
  box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1,
  svg {
    margin-bottom: 25px;
  }

  @media only screen and (max-width: 1440px) {
    .office-address {
      max-width: 390px;
    }

    .contact-info {
      width: fit-content;
    }
  }

  @media only screen and (max-width: 1365px) {
    flex-direction: column;

    .office-address,
    .contact-info {
      margin-bottom: 25px;
    }

    .col-lg-6,
    .col-lg-3 {
      min-width: 100%;
    }

    .office-map {
      padding: 0;

      a {
        width: 100%;
        height: 300px;
      }

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
  }

  @media only screen and (max-width: 426px) {
    margin-top: -170px;
    padding: 15px 10px;
    width: 90%;
  }
`
